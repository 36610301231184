import React from 'react';
import { 
  StyleSheet, 
  Text, 
  View, 
  Image, 
  TouchableOpacity,
  ScrollView,
  findNodeHandle
} from 'react-native';
import Button from '../../Common/components/Button';
import Input from '../../Common/components/Input';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import logo from '../../assets/login-bam-logo.png';

import emailIcon from '../../assets/email.png';
import passIcon from '../../assets/pass.png';

import { connect } from 'react-redux';
import { tryAuth } from '../actions/auth';
import { CHAMPIONSHIP_ID } from '../../Common/config/config';


class Login extends React.Component {

  state = {
    email : '',
    password: ''
  }

  goToForgottenPassword = () => {
    this.props.navigation.navigate('ForgottenPassword');
  }

  onLogin = () => {
     const authData = {
        email: this.state.email,
        password: this.state.password,
        championship_id: CHAMPIONSHIP_ID
    };

    this.props.onTryAuth(authData); 
  }

  componentDidUpdate(prevProps) {
    // Received new token - go to home screen
    if (this.props.token !== prevProps.token) {
      // load settings      
      this.props.navigation.navigate('Home');
    }
  }

  render() {
    return (
      <ScrollView 
        contentContainerStyle={styles.container} 
        style={styles.scrollViewStyle} ref="scrollView">
          <Background />
          <HeaderWithBack title={"ВХОД В ИГРАТА"} navigation={this.props.navigation} 
            style={{top: 0, left: 0, position: 'absolute'}} />

          <Image style={styles.logo} source={logo} />

          <View style={styles.formContainer}>
              <Input placeholder='Имейл адрес'                         
                  image={emailIcon}
                  keyboardType='email-address'
                  onChangeText={(val) => this.setState({email: val})}
                  value={this.state.email}
                  />

              <Input placeholder='Парола'                         
                  image={passIcon}
                  secureTextEntry
                  onChangeText={(val) => this.setState({password: val})}
                  value={this.state.password}
                  />


              <TouchableOpacity style={styles.forgottenPassword} onPress={this.goToForgottenPassword}>
                  <Text style={styles.forgottenPasswordText}>Забравена парола</Text>
              </TouchableOpacity>

              <Text style={styles.error}>{this.props.error}</Text>
              <Button title='ВХОД' style={styles.loginButton} onPress={this.onLogin} />
          </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    error: state.errors.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAuth: authData => dispatch(tryAuth(authData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex:1,
  },
  scrollViewStyle: {
    flex:1,
    width: '100%',
    height: '100%'    
  },
  logo: {
    width: '60%',
    height: 140,
    resizeMode: 'contain'
  },
  loginButton: {
    width: '100%',
    marginTop: 40
  },
  formContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '85%',
      marginTop: 40
  },
  forgottenPasswordText: {
    marginTop: 10,
    color: '#EAB845',
    fontSize: 20,
    fontWeight: 'bold'
  },
  error: {
    color: 'red'
  }
});
