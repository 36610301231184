import { SET_HOME_INFO, SET_BUDGET, DECREASE_BUDGET, INCREASE_BUDGET } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';

export const getHomeInfo = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');
        alert("Please login again, your session is expired!");
    })
    .then(token => {
        return fetch(BASE_URL + '/users/home', {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => {
      console.log(err);
    })
    .then(res => res.json())
    .then(res => {
      if (res.has_error && res.status === 503) {
        dispatch(setError('Кръга е затворен!', 503));
        return;
      }

      dispatch(setHomeInfo(res.data))
    });
  };
}

export const setHomeInfo = info => {
  return {
    type: SET_HOME_INFO,
    info
  };
};

export const setBudget = budget => {
  return {
    type: SET_BUDGET,
    budget
  };
};

export const decreaseBudget = sum => {
   return {
    type: DECREASE_BUDGET,
    sum
  };
}

export const increaseBudget = sum => {
   return {
    type: INCREASE_BUDGET,
    sum
  };
}