import React from 'react';
import { 
  StyleSheet, 
  Text, 
  View, 
  Image, 
  TouchableOpacity, 
  ScrollView,
  Platform,
  KeyboardAvoidingView,
  findNodeHandle
} from 'react-native';

import { DatePicker } from "material-ui-pickers";
import { withStyles } from '@material-ui/core/styles';
import PickerListPopup from '../../Common/components/PickerListPopup';

import HeaderWithBack from '../../Common/components/HeaderWithBack';
import Button from '../../Common/components/Button';
import Input from '../../Common/components/Input';
import Background from '../../Common/components/Background';

import bgImage from '../../assets/login-register-bg.png';
import logo from '../../assets/login-bam-logo.png';

import emailIcon from '../../assets/email.png';
import userIcon from '../../assets/username.png';
import cityIcon from '../../assets/location.png';
import teamIcon from '../../assets/team.png';
import passIcon from '../../assets/pass.png';
import dateIcon from '../../assets/date-of-birth.png';
import favoriteIcon from '../../assets/favorite-team.png';
import PlacesAutocomplete from 'react-places-autocomplete';

import { connect } from 'react-redux';
import { tryRegister } from '../actions/register';
import { getAllTeams } from '../../Common/actions/index';
import { CHAMPIONSHIP_ID } from '../../Common/config/config';

const API_KEY = "AIzaSyAZ72jyvsDD1B8gklELrtYgfmHrQsqHJyc";

class Register extends React.Component {

  state = {
    email : '',
    fullName: '',
    city: '',
    password: '',
    repeatPassword: '',
    teamName: '',
    birthDate: '',
    birthDateAsDate: new Date(),
    favorite_team_id: 0,
    conditionsCheck: false,
    error: '',
    showDatePicker: false,
    refferalCode: ''
  }


  componentDidMount() {
    this.props.getAllTeams(CHAMPIONSHIP_ID);
    if (window.sessionStorage.getItem('regState')) {
      this.setState(JSON.parse(window.sessionStorage.getItem('regState')));
      window.sessionStorage.removeItem('regState');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      //user registered and logged succesfully
      this.props.navigation.navigate('Home');
      return;
    }
    if (prevProps.error !== this.props.error) {
      this.setState({error: this.props.error});
    }
  }

  onRegister = () => {
    if (this.state.email.trim().length < 3) {
      this.setState({error: '* Липсващи или неправилни данни за Имейл'});
      return;
    }
    if (this.state.fullName.trim().length < 3) {
      this.setState({error: '* Липсващи или неправилни данни за Име и Фамилия'});
      return;
    }
    if (this.state.city.trim().length === 0) {
      this.setState({error: '* Трябва да изберете градът, от който сте'});
      return;
    }
    if ((this.state.password.trim().length < 5) ||
    (this.state.password !== this.state.repeatPassword))  {
      this.setState({error: '* Липсващи или неправилни данни за потвърдена парола'});
      return;
    }

    if (this.state.teamName.trim().length < 2) {
      this.setState({error: '* Неправилни данни за име на отбор'});
      return;
    }

    if (this.state.birthDate.trim().length < 2) {
      this.setState({error: '* Неправилни данни за рожденна дата'});
      return;
    }

    if (!this.state.favourite_team_id) {
      this.setState({error: '* Не сте избрали любим отбор'});
      return;
    }

    if (!this.state.conditionsCheck) {
      this.setState({error: '* Трябва да потвърдите общите условия'});
      return;
    }

    this.state.first_name = this.state.fullName.split(' ')[0];
    if (this.state.fullName.split(' ').length > 1)
      this.state.last_name = this.state.fullName.split(' ')[1];
    else 
      this.state.lastName = '';
    this.state.team_name = this.state.teamName;
    this.state.date_of_birth = this.state.birthDate;
    // this.state.inviter_code = this.state.refferalCode;
    const data = {...this.state};
    data.championship_id = CHAMPIONSHIP_ID;
    this.props.onRegister(data); 
  }

  toggleConditions = () => {
    this.setState(prevState => {
        return {
          conditionsCheck: !prevState.conditionsCheck
        }
    });
  }

  pad = num => {
    return ("" + num).length < 2 ? "0" + num : "" + num;
  };

  setBirthDate = date => {
    date = new Date(date);
    this.setState(prevState => {
      return {
        birthDateAsDate: date,
        birthDate: date.getFullYear() + "-" +
              this.pad(date.getMonth()+1) + "-" + 
              this.pad(date.getDate()),
      }
    });
  }

  handleChange = city => {
    this.setState({ city });
  };
 
  render() {
    const { classes } = this.props;   

    return (
      <ScrollView 
        contentContainerStyle={styles.container} 
        style={styles.scrollViewStyle}
        ref="scrollView">
        <Background />
        <HeaderWithBack title={"РЕГИСТРАЦИЯ"} navigation={this.props.navigation} 
            style={{top: 0, left: 0, position: 'absolute'}} />

        <Image style={styles.logo} source={logo} />

        <KeyboardAvoidingView style={styles.formContainer} behavior="padding" enabled>
            <Input placeholder='Имейл адрес'                         
                image={emailIcon}
                keyboardType='email-address'
                onChangeText={(val) => this.setState({email: val})}
                value={this.state.email} 
                style={styles.inputStyle}
                ref="email"
                onFocus={() => this.refs.scrollView.scrollTo({x:0, y:10})} />

            <Input placeholder='Име и фамилия'                         
              image={userIcon}
              onChangeText={(val) => this.setState({fullName: val})}
              value={this.state.fullName}
              style={styles.inputStyle}
              ref="fullName"
              onFocus={() => this.refs.scrollView.scrollTo({x:0, y:30})} />


            <View style={styles.autoCompleteContainer}>
              <Image style={styles.imageStyle} source={cityIcon}/>
              <PlacesAutocomplete
                value={this.state.city}
                onChange={this.handleChange}
                autoFocus={false}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div style={{width: '100%'}} autoComplete="off">
                    <input
                      autoFocus={false}
                      autoComplete="off"
                      {...getInputProps({
                        placeholder: 'Избери град',
                        className: 'location-search-input',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>              
            </View> 

            <Input placeholder='Парола'                         
                image={passIcon}
                secureTextEntry
                onChangeText={(val) => this.setState({password: val})}
                value={this.state.password}
                ref="password"
                autoComplete="false"
                onFocus={() => this.refs.scrollView.scrollTo({x:0, y:140})}
                style={styles.inputStyle} />

            <Input placeholder='Потвърди парола'                         
                image={passIcon}
                secureTextEntry
                onChangeText={(val) => this.setState({repeatPassword: val})}
                value={this.state.repeatPassword}
                ref="repeatPassword"
                onFocus={() => this.refs.scrollView.scrollTo({x:0, y:160})}
                style={styles.inputStyle} />

            <Input placeholder='Име на отбор'                         
              image={teamIcon}
              value={this.state.teamName}
              onChangeText={(val) => this.setState({teamName: val})}
              style={styles.inputStyle}
              ref="teamName"
              onFocus={() => this.refs.scrollView.scrollTo({x:0, y:270})}/>
                  
            <TouchableOpacity style={{width:'100%'}} onPress={()=>document.querySelector('div.test').click()}>
              <Input placeholder='Дата на раждане'                         
                image={dateIcon}
                value={this.state.birthDate} 
                editable={false}
                style={styles.inputStyle} />
            </TouchableOpacity> 

            <DatePicker 
            style={{display:'none'}}
            className="test"
            value={this.state.birthDate} 
            open={this.state.pickerOpened} 
            onChange={this.setBirthDate} />

            <PickerListPopup
              data={this.props.realTeams.map(t => {
                return {key: t.id, label: t.name}
              })} 
              open={this.state.teamsOpened}
              title='Избери отбор' 
              onClose={option => this.setState({
                favourite_team_id: option ? option.key : 0, 
                favoriteTeam: option ? option.label : '',
                teamsOpened: false})
              }/>
          
          <TouchableOpacity onPress={()=>this.setState({teamsOpened: true})} style={{width: '100%'}}>
            <Input placeholder='Избери любим отбор'                         
                  image={favoriteIcon}
                  placeholderTextColor='#B3833D'
                  editable={false}       
                  value={this.state.favoriteTeam}
                  style={styles.inputStyle}
                  inputStyle={styles.favoriTeamInput} />
          </TouchableOpacity>

          {/* <Input placeholder='Refferal code'                         
              image={teamIcon}
              value={this.state.refferalCode}
              onChangeText={(val) => this.setState({refferalCode: val})}
              style={styles.inputStyle}
              ref="refferalCode"
              onFocus={() => this.refs.scrollView.scrollTo({x:0, y:370})}/> */}

            <View style={styles.checkboxContainer}>
            <input type="checkbox" onClick={this.toggleConditions} />
            <Text style={styles.agreeText}>Съгласен съм с </Text>
              <TouchableOpacity onPress={() => {
                window.sessionStorage.setItem('regState', JSON.stringify(this.state));
                this.props.navigation.navigate('Terms');
              }}>
                <Text style={styles.greenText}>общите условия</Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.error}>{this.state.error}</Text>
            <Button title='РЕГИСТРАЦИЯ' style={styles.loginButton} onPress={this.onRegister} />
        </KeyboardAvoidingView>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    realTeams: state.realTeams.realTeams,
    token: state.auth.token,
    error: state.errors.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRegister: data => dispatch(tryRegister(data)),
    getAllTeams: (id) => dispatch(getAllTeams(id)),
  };
};



const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  scrollViewStyle: {
    flex:1,
    width: '100%',
    height: '100%'    
  },
  logo: {
    width: '60%',
    height: 140,
    resizeMode: 'contain',
    marginTop: 50
  },
  loginButton: {
    width: '100%',
    marginTop: 40
  },
  formContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '85%',
      marginTop: 40,
      marginBottom: 50
  },
  favoriTeamInput: {
    borderColor: '#B3833D',
    color: '#B3833D',
    width: '100%'
  },
  autoCompleteContainer: {
      width: '100%',
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderColor: '#bbb',                     
  },
  imageStyle: {
      width:20,
      height: 20,
      marginTop: 10,
      marginBottom: 5,
      marginLeft: 8
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkbox: {
    backgroundColor: "transparent",
    borderWidth: 0
  },
  checkboxText: {
    color: 'white',
    fontSize:18,
  },
  greenText: {
    fontWeight:'bold',
    fontSize:18,
    color: '#28ED57',
  },
  agreeText: {
    fontWeight:'bold',
    fontSize:18,
    color: 'white',
  },
  inputStyle: {
    marginVertical: 10,
    width: '100%'
  },
  dropDownStyle: {
    marginVertical: 10,
    width: '100%'
  },
  error: {
    color: 'red', 
    width: '100%', 
    textAlign: 'center'
  }
});

const styles2 = theme => ({
  textField: {
    width: '100%',
    lineHeight: 20,
    fontSize: 19,
    color: 'white'
  },
  root: {
    color: 'white',
    backgroundColor: 'yellow'
  },
  '& label.Mui-focused': {
      color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    }
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles2)(Register));