import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, Linking, ScrollView, StatusBar } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Background from '../../Common/components/Background';
import LinkView from '../../Common/components/LinkView';
import ContainerWithHeader from '../../Common/components/ContainerWithHeader';
import privateLeagueImg from '../../assets/private-league.png';
import firstPlaceImg from '../../assets/1-st-place.png';
import questionIcon from '../../assets/question.png';
import gongLeagueIcon from '../../assets/gong-league-black.svg.png';
import logo from '../../assets/login-bam-logo.png';
import { connect } from 'react-redux';
import { getLeaguesRankings } from '../actions/leaguesRankings';
import TabBar from '../../Common/components/TabBar';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import LoadingScreen from '../../Common/components/LoadingScreen';

class LeagueRankings extends React.Component {
  static navigationOptions = {
    title: 'Лиги',
  };

  componentDidMount() {
    this.props.getLeaguesRankings();
  }

  componentDidUpdate() {
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
      return;
    }
  }

  goToDetails = ranking => {
    this.props.navigation.navigate('LeagueDetails', {
      leagueId: ranking.id
    });
  }

  render() {
    if (!this.props.leaguesRankings) return <LoadingScreen />;

    return (
      <React.Fragment>

        <HeaderWithBack title={"Лиги"} hideBack={true} navigation={this.props.navigation} />
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
            <Background />

            <Image style={styles.logo} source={logo} />
            <View style={styles.innerContainer}>
              {this.props.leaguesRankings.map(ranking => (
                <TouchableOpacity style={styles.rowContainer} key={ranking.id}
                  onPress={() => this.goToDetails(ranking)}>
                  <View style={styles.leagueNameContainer}>
                    {ranking.isPrivateLeague &&
                      <Image style={styles.tableImg} source={privateLeagueImg} />}
                    {ranking.rank === 1 &&
                      <Image style={styles.tableImg} source={firstPlaceImg} />}
                    <Text style={styles.rowText}>{ranking.title}</Text>
                  </View>

                  <View style={styles.leagueContainer}>
                    {
                      // <Ionicons name={ranking.iconName} size={20} 
                      //           color={ranking.color} style={{marginRight: 5}} />
                    }
                    <Text style={styles.strongRowText}>{ranking.rank}</Text>
                  </View>
                </TouchableOpacity>
              ))}

              {!this.props.leaguesRankings.some(l => l.title === 'Gong') &&
                <TouchableOpacity style={styles.rowContainer} key={4003}
                  onPress={() => this.props.navigation.navigate('GongLeague')}>
                  <View style={styles.leagueNameContainer}>
                    <Image style={[styles.tableImg, { width: 50 }]} source={gongLeagueIcon} />
                  </View>
                  <View style={[styles.leagueContainer, { width: 100 }]}>
                    <Text style={styles.strongRowText}>Включи се!</Text>
                  </View>
                </TouchableOpacity>}


              <LinkView title="ВКЛЮЧИ СЕ В ЛИГА"
                page="JoinLeague" navigation={this.props.navigation} />
              <LinkView title="СЪЗДАЙ ЛИГА"
                page="CreateLeague" navigation={this.props.navigation} />
            </View>
            
        </ScrollView>
        <TabBar navigation={this.props.navigation} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLeaguesRankings: () => dispatch(getLeaguesRankings())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leaguesRankings: state.leaguesRankings.leaguesRankings,
    errorCode: state.errors.code
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueRankings);

const styles = EStyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  logo: {
    width: 200,
    height: 200,
    // marginTop: 60,
    // marginBottom: 80,
    resizeMode: 'contain'
  },
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    backgroundColor: 'white',
    borderRadius: 10,
    marginVertical: 5,
    width: '90%'
  },
  rowText: {
    fontSize: 17,
    color: 'black',
    fontWeight: 'bold'
  },
  strongRowText: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  leagueContainer: {
    width: '5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leagueNameContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableImg: {
    width: 20,
    height: 20
  },
});
