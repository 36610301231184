import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class Faq extends React.Component {

    render() {
        return (
        <React.Fragment>
            <HeaderWithBack title="Често задавани въпроси" navigation={this.props.navigation}/>
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background style={{height: Dimensions.get('screen').height * 1.8}}/>
                <View style={{width: '100%', flex: 1}}>
                    <Text style={styles.headingText}>Често задавани въпроси</Text>
                    

                    <Text style={styles.aboutHeading}>Кога ще стартира играта?</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                        Очаквайте официалния старт до 10-ти юли! 
                        </Text>
                    </View>

                    <Text style={styles.aboutHeading}>От къде ще можем да свалим играта?</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                        От Apple App Store и Goole Play Store.
                        </Text>
                    </View>

                    <Text style={styles.aboutHeading}> Как да разберем, че играта е излязла? </Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                            Абонирайте се тук
                            <Text style={styles.aboutText}> или следете постовете ни</Text> 
                            <Text style={styles.aboutText}> в нашата фейсбук страница тук</Text>
                        </Text>
                    </View>

                    <Text style={styles.aboutHeading}> Лесно ли ще се играе играта? </Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                        След официалния старт ще ви предоставим видео обучение, с помощта на което много бързо и лесно ще навлезете в играта. 
                        </Text>
                    </View>

                    <Text style={styles.aboutHeading}>Кога ще стартира играта?</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                        Очаквайте официалния старт до 10-ти юли! 
                        </Text>
                    </View>

                    <Text style={styles.aboutHeading}> Безплатна ли ще е играта?</Text>
                    <View style={styles.aboutContainer}>
                        <Text style={styles.aboutText}>
                        Да, играта ще бъде свободна за сваляне! 
                        </Text>
                    </View>
                </View>
            </ScrollView>
        </React.Fragment>
        )
    }
}

export default Faq;

const styles = StyleSheet.create({
  scrollView: {    
    width: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headingText: {
      fontWeight:'bold',
      color: 'white',
      fontSize: 24,
      marginBottom: 12,
      marginLeft: 15,
      width: '100%',
      marginTop: 220
  },
  aboutHeading: {
      color: 'white',
      fontSize: 20,
      width: '100%',
      paddingLeft: 10,
      marginVertical: 15
  },
  aboutContainer: {
      backgroundColor: '#E7EAF9',
      width: '100%',
      padding: 10,
  },
  aboutText: {
      fontSize: 17,
      marginVertical: 15
  },
  version: {
      fontWeight: 'bold',
      fontSize: 17,
  }
});