import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, Linking, ScrollView, StatusBar } from 'react-native';
import Background from '../../Common/components/Background';
import LoadingScreen from '../../Common/components/LoadingScreen';
import LinkView from '../../Common/components/LinkView';
import ContainerWithHeader from '../../Common/components/ContainerWithHeader';
import RefreshContainer from '../../Common/components/RefreshContainer';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import PaymentPopup from '../components/PaymentPopup';
import InfoPopup from '../components/InfoPopup';
import gongAd from '../../assets/gong_ad.png';
import privateLeagueImg from '../../assets/private-league.png';
import captainChipIcon from '../../assets/captain-chip.png';
import fullTeamChipIcon from '../../assets/full-team-chip.png';
import viceCaptainChipIcon from '../../assets/vice-captain-chip.png';
import fullPackageIcon from '../../assets/buy-bundle-chips.png';
import questionIcon from '../../assets/question.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import TabBar from '../../Common/components/TabBar';
import 'react-circular-progressbar/dist/styles.css';
// import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
// import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import { getChampionshipInfo } from '../../Login/actions/championship';
import { getHomeInfo } from '../actions/home';
import { getAds } from '../../Common/actions/ads';
import { connect } from 'react-redux';
import { BASE_URL } from "../../Common/config/config";

class Home extends React.Component {
  state = {
    paymentPopupVisible: false,
    hideSmsCode: false,
    choosenChip: '',
    infoPopupVisible: false,
    infoPopupTitle: '',
    refreshing: false
  }

  static navigationOptions = {
    title: 'FPL Fantasy Manager',
  };

  componentDidMount() {
    StatusBar.setHidden(true);
    this.props.getAds();
    this.props.getChampionshipInfo();    
    this.props.getHomeInfo();
  }

   _onRefresh = () => {
      this.setState({refreshing: true});
      this.props.getHomeInfo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
      return;
    }
    if (this.props.homeInfo) {
      window.localStorage.setItem("championshipId", this.props.homeInfo.championshipId)
    }
    if (this.props.isNewlyRegistered && 
        this.props.championshipInfo && 
        this.props.homeInfo) {
       
        this.props.navigation.navigate('Team');
    }
    if (prevProps.homeInfo !== this.props.homeInfo && this.state.refreshing) {
      this.setState({refreshing: false});
    }
    if (this.props.navigation !== prevProps.navigation) {
      this._onRefresh();
      this.props.getAds();
      this.props.getChampionshipInfo();   
    }
  }

  getPercent = () => {
    if (this.props.homeInfo.maxScore === 0) return 0;
    const res = Math.floor((this.props.homeInfo.lastRoundPoints / this.props.homeInfo.maxScore) * 100);
    return isNaN(res) ? 0 : res;
  }

  goToDetails = ranking => {
      this.props.navigation.navigate('LeagueDetails', {
        leagueName: ranking.title,
        leagueId: ranking.id
      });
  }

  hidePopup = () => {
    this.setState({
      paymentPopupVisible: false,
      hideSmsCode: false,
    });
  }

  payWithCard = () => {
    //TODO: card payment
    this.hidePopup();
  }

  payWithSms = () => {
    //TODO: sms payment
    this.hidePopup();
  }

  buyChip = (choosenChip, disableSms) => {
    if (!choosenChip.canBuy) return;
    this.setState({
       paymentPopupVisible: true,
       hideSmsCode: !!disableSms,
       choosenChip
    });
  }

  showInfo = chip => {
    this.setState({
      infoPopupVisible: true,
      infoPopupTitle: chip.description.toUpperCase()
    });
  }

  showInfoBuyChip = () => {
     this.setState({
      infoPopupVisible: true,
      infoPopupTitle: 'ФУНКЦИЯТА ВСЕ ОЩЕ НЕ Е АКТИВНА'
    });
  }

  getChipIcon = id => {
    switch (id) {
      case 2: return captainChipIcon;
      case 4: return fullTeamChipIcon;
      case 6: return viceCaptainChipIcon;
      case 7: return fullPackageIcon;
      default: return captainChipIcon;
    }
  }

  render() {
    if (!this.props.homeInfo) {
      return <LoadingScreen />;
    }
    const ad = this.props.ads.find(ad => ad.position.id === 1);
    const ad2 = this.props.ads.find(ad => ad.position.id === 0);
    this.props.homeInfo.rankings && this.props.homeInfo.rankings.forEach(ranking => {
        if (ranking.change === 'down') {
          ranking.iconName = 'md-arrow-dropdown';
          ranking.color = 'red';
        } else if (ranking.change === 'up') { 
          ranking.iconName = 'md-arrow-dropup';
          ranking.color = 'green';
        } else if (ranking.change === 'same') { 
          ranking.iconName = 'md-arrow-dropright';
          ranking.color = 'orange';
        }
    });

    return (
      <React.Fragment>
      <PaymentPopup 
        isVisible={this.state.paymentPopupVisible} 
        hideSmsCode={this.state.hideSmsCode}
        onBankCardSelected={this.payWithCard}
        onSmsCodeSelected={this.payWithSms}
        onDismiss={this.hidePopup}/>

      <InfoPopup title={this.state.infoPopupTitle}
      isVisible={this.state.infoPopupVisible} 
      onDismiss={() => this.setState({infoPopupVisible: false}) }/>
      <HeaderWithBack title={"FPL Fantasy Manager"} hideBack={true} navigation={this.props.navigation}/> 
      <RefreshContainer refreshing={this.state.refreshing} onRefresh={this._onRefresh}>
          <Background style={{height: '60%'}}/>
          
          <View style={styles.innerContainer}>
              
                <View style={{marginVertical: 100, width: 140, height: 140}}>
                  <span className="pulse"/>
                  <CircularProgressbar value={this.getPercent()}
                  text={''+this.props.homeInfo.lastRoundPoints} background={true}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                    // Text size
                    textSize: '32px',
                    fontWeight: 'bold',
                    // Colors
                    textColor: '#479D2D',
                    trailColor: 'black',
                    pathColor: '#5E9A3F',
                    backgroundColor: '#0D0D1B',
                  })} />
                </View>

              <View style={styles.headerView}>
                <Text style={styles.headerText}>Кръг {this.props.homeInfo.currentRound} </Text>

                <View style={styles.headerInfo}>
                  <Text style={styles.headerInfoText}>средно {this.props.homeInfo.avgScore} </Text>
                  <Text style={styles.headerInfoText}>рекорд {this.props.homeInfo.maxScore} </Text>
                </View>
              </View>
              {ad2 && <TouchableOpacity style={styles.adImageContainer} 
                onPress={() => window.open(ad2.url, '_blank')}>
                <Image style={styles.adImage} source={{uri: ad2.image.url}} />
              </TouchableOpacity>}
              <LinkView title="Отбор" page="Team" navigation={this.props.navigation}/>
              <LinkView title="Точки" page="Points" navigation={this.props.navigation}/>
              <LinkView title="Трансфери" page="Transfers" navigation={this.props.navigation}/>
              <LinkView title="Класиране Първа Лига" page="LeagueTable" navigation={this.props.navigation}/>
              <LinkView title="Статистики" page="Stats" navigation={this.props.navigation}/>
              {ad && <TouchableOpacity style={styles.adImageContainer} 
                onPress={() => window.open(ad.url, '_blank')}>
                <Image style={styles.adImage} source={{uri: ad.image.url}} />
              </TouchableOpacity>}
              <LinkView title="Програма и Резултати" page="Results" navigation={this.props.navigation}/>

              <ContainerWithHeader title="Класиране Лиги">
                <View style={styles.rowContainer}>
                  <Text style={styles.rowText}>ЛИГА</Text>
                  <Text style={styles.strongRowText}>ПОЗИЦИЯ</Text>
                </View>
                {this.props.homeInfo.rankings && this.props.homeInfo.rankings.map(ranking => {
                  return (
                    <TouchableOpacity style={styles.rowContainer} 
                    key={ranking.id} 
                    onPress={() => this.goToDetails(ranking)}>

                      <View style={[styles.leagueNameContainer]}>
                        {ranking.isPrivateLeague && <Image style={styles.tableImg} source={privateLeagueImg} />}
                        <Text style={styles.rowText}>{ranking.title}</Text>
                      </View>

                      <View style={styles.leagueContainer}>
                        { 
                          //TODO:
                          //<Ionicons name={ranking.iconName} size={25} color={ranking.color} />
                        }

                        <Text style={styles.strongRowText}>{ranking.rank}</Text>
                      </View>

                    </TouchableOpacity>);
                })}
              </ContainerWithHeader>

              {/* <ContainerWithHeader title="Допълнителни бонуси и схеми">
                {this.props.homeInfo.additionalChips && this.props.homeInfo.additionalChips.map(chip => (
                  <View style={[styles.rowContainer, {borderBottomWidth: 0}]} key={chip.id}>
                    <View style={styles.packageInfo}>
                      <Image style={styles.tableImg} source={this.getChipIcon(chip.id)} />
                      <Text style={[styles.rowText, {marginHorizontal: 7}]}>{chip.name}</Text>
                      <TouchableOpacity onPress={() => this.showInfo(chip)}>
                        <Image style={styles.tableImg} source={questionIcon} />
                      </TouchableOpacity> 
                    </View>
                    {
                      //id == 7 is full package - only with bank card for payment
                    }
                    <TouchableOpacity style={[styles.buyButton, !chip.canBuy ? {opacity:0.6} : null]} 
                      onPress={
                        // () => this.buyChip(chip, chip.id === 7)
                        this.showInfoBuyChip
                      }>
                      <Text style={styles.buyButtonText}>КУПИ ЗА {Number(chip.price).toFixed(2)} ЛВ.</Text>
                    </TouchableOpacity>
                  </View>
                ))}

                {this.props.homeInfo.schemeChip && 
                <View style={styles.promotionsFooter}>
                  <Text style={styles.schemesText}>{this.props.homeInfo.schemeChip.name}</Text>
                  <TouchableOpacity style={[styles.buyButton, {backgroundColor: '#E4A647', width: 150}, !this.props.homeInfo.schemeChip.canBuy ? {opacity:0.6} : null]}
                      onPress={() => 
                        // this.buyChip(this.props.homeInfo.schemeChip)
                        this.showInfoBuyChip()
                      }>
                    <Text style={styles.buyButtonText}>КУПИ ЗА {Number(this.props.homeInfo.schemeChip.price).toFixed(2)} ЛВ.</Text>
                  </TouchableOpacity>
                </View>}
              </ContainerWithHeader> */}

              <ContainerWithHeader title={"Отбор " + this.props.homeInfo.teamName}>
                  <View style={styles.rowContainer}>
                    <View style={[styles.leagueNameContainer]}>
                      <Text style={styles.rowText}>{"Точки кръг " + (this.props.homeInfo.currentRound)}</Text>
                    </View>
                    <View style={[styles.leagueContainer, {justifyContent: 'flex-end'}]}>
                      <Text style={styles.strongRowText}>{this.props.homeInfo.lastRoundPoints}</Text>
                    </View>
                  </View>
                  <View style={styles.rowContainer}>
                    <View style={[styles.leagueNameContainer]}>
                      <Text style={styles.rowText}>Общо точки</Text>
                    </View>
                    <View style={[styles.leagueContainer, {justifyContent: 'flex-end'}]}>
                      <Text style={styles.strongRowText}>{this.props.homeInfo.totalPoints}</Text>
                    </View>
                  </View>
                  <View style={styles.rowContainer}>
                    <View style={[styles.leagueNameContainer]}>
                      <Text style={styles.rowText}>Позволени Трансфери</Text>
                    </View>
                    <View style={[styles.leagueContainer, {justifyContent: 'flex-end'}]}>
                      <Text style={styles.strongRowText}>{this.props.homeInfo.allowedTransfers}</Text>
                    </View>
                  </View>
                  <View style={styles.rowContainer}>
                    <View style={[styles.leagueNameContainer]}>
                      <Text style={styles.rowText}>Бюджет</Text>
                    </View>
                    <View style={[styles.leagueContainer, {justifyContent: 'flex-end'}]}>
                      <Text style={styles.strongRowText}>{this.props.homeInfo.budget}</Text>
                    </View>
                  </View>
                  <View style={styles.rowContainer}>
                    <View style={[styles.leagueNameContainer]}>
                      <Text style={styles.rowText}>Стойност на отбора</Text>
                    </View>
                    <View style={[styles.leagueContainer, {justifyContent: 'flex-end'}]}>
                      <Text style={styles.strongRowText}>{this.props.homeInfo.teamValue}</Text>
                    </View>
                  </View>
              </ContainerWithHeader>
          </View>
          <TabBar navigation={this.props.navigation}/>
      </RefreshContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ads: state.ads.ads,
    homeInfo: state.home.info,
    isNewlyRegistered: state.register.isNewlyRegistered,
    championshipInfo: state.championship.championshipInfo,
    errorCode: state.errors.code
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getAds: () => dispatch(getAds()),
    getChampionshipInfo: () => dispatch(getChampionshipInfo()),
    getHomeInfo: () => dispatch(getHomeInfo())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  container: {
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 80
  },
  progressText: { 
    fontSize: 45, 
    color: '#72E86C', 
    fontWeight: 'bold' 
  },
  headerView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    marginTop: -70
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  headerInfo: {
    flexDirection: 'row'
  },
  headerInfoText: {
    marginHorizontal: 15,
    fontSize:20
  },
  spinner: {
    marginBottom: 50
  },
  adImageContainer: {
    width: '90%',
    borderRadius: 10,
    marginVertical: 10,
  },
  adImage: {
    width: '100%',
    height: 40,
    borderRadius: 10
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: '#F3F3F3',
  },
  rowText: {
    fontSize: 13,
    color: 'darkgrey'
  },
  strongRowText: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  leagueContainer: {
    width: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leagueNameContainer: {
    width: 170,
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableImg: {
    width: 20,
    height:20
  },
  packageInfo: {
    flexDirection: 'row',
    width: 150,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buyButton: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#72E86C',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buyButtonText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  promotionsFooter: {
    backgroundColor: '#2053A4',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20
  },
  schemesText: {
    fontSize: 18,
    color: 'white',
    marginVertical: 15
  }
});
