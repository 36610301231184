import { MAIL_SENT, REGISTER_USER, USER_READY } from "../actions/actionTypes";

const initialState = {
  mailSent: false,
  isNewlyRegistered: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        isNewlyRegistered: true
      };
    case USER_READY:
      return {
        ...state,
        isNewlyRegistered: false
      };
    case MAIL_SENT:
      return {
        ...state,
        mailSent: true
      };
    default:
      return state;
  }
};

export default reducer;