import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView, StatusBar } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

class Awards extends React.Component {

    render() {
        return (
        <React.Fragment>
            <HeaderWithBack title="Награди" navigation={this.props.navigation} />
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background />
                <Text style={styles.headingText}>НАГРАДИ НА FPL Fantasy Manager</Text>
                <View style={styles.htmlContainer}>
                    {renderHTML()}
                </View>   
            </ScrollView>
        </React.Fragment>
        )
    }
}

export default Awards;

const styles = StyleSheet.create({
  scrollView: {    
    width: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headingText: {
      fontWeight:'bold',
      color: 'white',
      fontSize: 24,
      marginBottom: 12,
      marginLeft: 15,
      width: '100%',
      marginTop: 200
  },
  htmlContainer: {
      width: '100%',
      flex: 1,
      backgroundColor: 'white'
  }
});


function renderHTML() {
    return (
<div class="kc-elm kc-css-569281 kc_text_block">
<h3>СЕДМИЧНА НАГРАДА ЗА ПОБЕДИТЕЛ НА КРЪГА</h3>
<ul>
<li>2 билета за домакински мач по избор на стадион “Васил Левски” на Българския Националния Отбор по футбол</li>
</ul>
<h3>ПОБЕДИТЕЛИ В ПУБЛИЧНАТА ЛИГА НА ЛЮБИМ БЪЛГАРСКИ ОТБОР</h3>
<p><strong>1-во място:</strong></p>
<ul>
<li>футболна фланелка</li>
<li>2 билета за домакински мач по избор на стадион “Васил Левски” на Българския Национален Отбор по футбол</li>
</ul>
<h3>ГОЛЕМИ НАГРАДИ ЗА ПОБЕДИТЕЛИТЕ В ИГРАТА</h3>
<p><strong>1-во място:</strong></p>
<ul>
<li>2 екипa на Българския Национален Отбор по футбол</li>
<li>2 билета за&nbsp;<strong>ВСЕКИ ДОМАКИНСКИ МАЧ ЗА СЛЕДВАЩИТЕ КВАЛИФИКАЦИИ</strong>&nbsp;на стадион “Васил Левски” на Българския Национален Отбор по футбол</li>
</ul>
<p><strong>2-ро място:</strong></p>
<ul>
<li>екип на Българския Национален Отбор по футбол</li>
<li>2 билета за домакински мач по избор на стадион “Васил Левски” на Българския Национален Отбор по футбол</li>
</ul>
<p><strong>3-то място:</strong></p>
<ul>
<li>екип на Българския Национален Отбор по футбол</li>
<li>2 билета за домакински мач по избор на стадион “Васил Левски” на Българския Национален Отбор по футбол</li>
</ul>
<h3><u>ВАЖНО:</u></h3>
<p>* На всички победители ще бъдат изпращани персонално имейли с информация кога и как ще си получат наградите. Моля, участвайте в играта с реални и използвани имейли!</p>
<p>* Материалните награди се изпращат 2 пъти в рамките на един сезон сезон – <strong>по време на зимната пауза и след края сезона</strong></p>
<p>*&nbsp;<strong>Заявката</strong>&nbsp;за билетите на Българския Националнен Отбор по футбол трябва да бъде&nbsp;<strong>10 дни преди конкретния мач!</strong></p>
</div>);
}