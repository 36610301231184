import React from 'react';
import { StyleSheet, Text, View, Image, 
TouchableOpacity, AsyncStorage, StatusBar } from 'react-native';
import Button from '../../Common/components/Button';
import Background from '../../Common/components/Background';

import bgImage from '../../assets/login-register-bg.png';
import logo from '../../assets/login-bam-logo.png';
import sponsorLogo1 from '../../assets/bamf_logo.png';

import { connect } from 'react-redux';
import { authSetToken } from '../../Login/actions/auth';

class Welcome extends React.Component {

  constructor(props) {
    super(props);
    StatusBar.setHidden(true);
    this._bootstrapAsync();
  }

  _bootstrapAsync = () => {
    AsyncStorage.getItem('token').then(userToken => {
       if (userToken) {         
        this.props.authSetToken(userToken);
        this.props.navigation.navigate('Home');
       }
    });
  };

  goToLogin = () => {
      this.props.navigation.navigate('Login');
  }

  goToRegister = () => {
    this.props.navigation.navigate('Register');
  }

  render() {
    return (
      <View style={styles.container}>
        <Background />
        <Image style={styles.logo} source={logo} />

          
        <Button style={styles.loginButton} 
                  onPress={this.goToLogin}
                  title="Вход в играта" />
        <TouchableOpacity style={styles.newAccountContainer} onPress={this.goToRegister}>
          <Text style={styles.newAccountText}>Нов акаунт</Text>
        </TouchableOpacity>

        <View style={styles.sponsorsContainer}>
          <Image style={styles.sponsorImage} source={sponsorLogo1} />
        </View>
      </View>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    authSetToken: token => dispatch(authSetToken(token))
  }
}

export default connect(null, mapDispatchToProps)(Welcome);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: '60%',
    height: 140,
    resizeMode: 'contain'
  },
  loginButton: {
    marginTop: 130,
    width: '75%',
  },
  newAccountText: {
    marginTop: 40,
    color: '#EAB845',
    fontSize: 20,
    fontWeight: 'bold'
  },
  sponsorsContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sponsorImage: {
    width: 150,
    height: 100,
    resizeMode: 'contain',
    marginLeft: 10,
    borderRadius: 5
  }
});
