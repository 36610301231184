import { TRY_AUTH, AUTH_SET_TOKEN, MAIL_SENT, REGISTER_USER, USER_READY } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { tryAuth } from '../../Login/actions/auth';
import { setError } from '../../Common/actions/errors';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";

export const tryRegister = data => {
  return dispatch => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + '/users', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
    })
    .catch(err => {
      console.log(err);
      alert("Registration failed, please try again!");
      dispatch(uiStopLoading());
    })
    .then(res => res.json())     
    .then(res => {     
      if (res.has_error) {
        dispatch(setError(res.message));
      } else {
        dispatch(setError(''));
        dispatch(setNewUser());
        dispatch(tryAuth({email: data.email, password: data.password}));
      }
    });
  };
}

export const setNewUser = () => {
    return {
        type: REGISTER_USER
    };
};

export const makeUserReady = () => {
    return {
        type: USER_READY,
    };
};